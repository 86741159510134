/* Store Page Layout */
.store-page {
  padding: 20px;
  text-align: center;
  background: linear-gradient(145deg, #f0f5f9, #e0e5eb);
  min-height: 100vh;
}

/* Banner Section */
.banner {
  position: relative;
  width: 100%;
  height: 500px; /* Adjust this height as needed based on the image */
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  animation: fadeIn 2s ease-in-out;
}

.farmer-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the container without distortion */
  object-position: center top; /* Adjust to show the top of the image (face) */
  transition: transform 0.6s ease-in-out, filter 0.6s ease-in-out;
}



.farmer-image:hover {
  transform: scale(1.08);
  filter: grayscale(0%) brightness(1);
}

.banner-text {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 20px;
  font-size: 1.7em;
  font-weight: bold;
  border-radius: 12px;
  animation: slideUp 1.5s ease-out;
}

/* Product Grid */
.product-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 25px;
  padding: 20px;
}

/* Product Card */
.product-card {
  border: 2px solid #ddd;
  border-radius: 12px;
  padding: 20px;
  width: 260px;
  background-color: white;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.4s ease-in-out, box-shadow 0.4s ease-in-out;
  position: relative;
  perspective: 1000px; /* Enables the 3D effect */
}

.product-card:hover {
  transform: translateY(-10px) rotateX(5deg) rotateY(5deg);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.product-image {
  width: 100%;
  height: 180px;
  object-fit: cover;
  margin-bottom: 10px;
  transition: transform 0.5s ease-in-out;
}

.product-image:hover {
  transform: scale(1.15) rotate(3deg);
}

.price {
  font-size: 1.3em;
  font-weight: bold;
  color: #333;
  margin: 12px 0;
}

/* Buy Button */
.buy-button {
  background-color: #28a745;
  color: white;
  padding: 12px 25px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1.1em;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.buy-button:hover {
  background-color: #218838;
  transform: scale(1.08);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
}

/* Category Filter */
.category-filter {
  display: flex;
  gap: 20px;
  margin-bottom: 40px;
  justify-content: center;
  flex-wrap: wrap;
}

.category-filter button {
  padding: 14px 30px;
  border: 3px solid transparent;
  border-radius: 12px;
  background: linear-gradient(145deg, #e0e0e0, #f0f0f0);
  color: #333;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  position: relative;
}

.category-filter button:hover {
  background: linear-gradient(145deg, #d0d0d0, #e5e5e5);
  border-color: #bbb;
  transform: scale(1.05);
}

.category-filter button.active {
  background: #007bff;
  color: #fff;
  border-color: #007bff;
  font-weight: 700;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(30px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
