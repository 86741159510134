.modal {
    display: flex; /* Use flexbox for centering */
    justify-content: center;
    align-items: center;
    position: fixed; /* Fixed positioning */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    z-index: 1000; /* Ensure modal is on top */
    overflow-y: auto; /* Allow scrolling if the content overflows */
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    max-width: 500px; /* Set a max width for the modal */
    width: 90%; /* Make the modal responsive */
  }
  
  .modal h2 {
    margin-top: 0;
  }
  
  .modal form {
    display: flex;
    flex-direction: column;
  }
  
  .modal input,
  .modal select,
  .modal textarea {
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px; /* Consistent font size */
  }
  
  .modal textarea {
    resize: vertical;
  }
  
  .modal button {
    padding: 10px;
    background-color: #28a745; /* Green for submit */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    font-size: 16px; /* Increase button font size */
    transition: background-color 0.3s; /* Smooth transition */
  }
  
  .modal button:hover {
    background-color: #218838; /* Darker green on hover */
  }
  
  .close-button {
    padding: 10px; /* Add padding for better click area */
    background: transparent;
    border: none;
    color: #007bff; /* Blue color for close button */
    cursor: pointer;
    margin-top: 10px; /* Add margin for spacing */
    font-size: 16px; /* Increase close button font size */
  }
  
  .close-button:hover {
    text-decoration: underline; /* Underline on hover for effect */
  }
  