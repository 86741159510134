/* Header */
.header {
  background-color: #ffffff;
  padding: 10px 0;
  border-bottom: 1px solid #e0e0e0;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.logo {
  width: 60px;
  height: 60px;
}

.logo-img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.navbar {
  display: flex;
  align-items: center;
}

.nav-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.nav-item {
  margin-right: 20px;
}

.nav-item a {
  text-decoration: none;
  color: #333333;
  font-size: 16px;
}

.nav-item a:hover {
  color: #007bff;
}

/* Service Boxes Section */
#service-boxes {
  padding: 40px 20px;
  background-color: #f8f9fa;
}

.service-boxes-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 1200px;
  margin: 0 auto;
}

.service-box {
  width: 30%;
  background-color: #ffffff;
  padding: 20px;
  margin-bottom: 20px;
  text-align: center;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.service-box:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.service-box h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.service-box p {
  font-size: 1em;
  line-height: 1.4;
}

.service-logo {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  border-radius: 50%;
  object-fit: cover;
}

/* Language Icons Section */
.language-icons-container {
  padding: 20px;
  background-color: #f8f9fa;
  overflow: hidden;
}

.language-icons {
  display: flex;
  animation: marquee 15s linear infinite;
}

.language-icons img {
  width: 100px;
  height: 100px;
  margin: 0 20px;
  border-radius: 50%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.language-icons img:hover {
  transform: scale(1.1);
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* Carousel */
.carousel-container {
  margin-top: 20px;
  max-width: 100%;
}

.carousel-slide {
  position: relative;
}

.carousel-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.8);
  padding: 10px;
  border-radius: 5px;
  max-width: 80%;
  text-align: center;
}

.content-wrapper {
  color: #333333;
}

.purchase-button {
  background-color: #007bff;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.purchase-button:hover {
  background-color: #0056b3;
}

.price {
  margin-top: 5px;
  font-size: 14px;
}

/* Modal */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #ffffff;
  width: 80%;
  max-width: 600px;
  padding: 20px;
  border-radius: 5px;
  position: relative;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
}

.modal-body {
  display: flex;
}

.modal-left {
  flex: 1;
  text-align: center;
}

.modal-image {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
}

.modal-right {
  flex: 1;
  padding: 0 20px;
}

form {
  margin-top: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
}

.submit-button {
  background-color: #28a745;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.submit-button:hover {
  background-color: #218838;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .container {
    padding: 0 10px;
  }

  .nav-item {
    margin-right: 15px;
  }

  .service-box {
    width: 45%; /* Adjust width for tablets */
  }

  .language-icons img {
    width: 80px; /* Adjust size for tablets */
    height: 80px;
  }
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
    align-items: flex-start;
    padding: 0 10px;
  }

  .navbar {
    margin-top: 10px;
  }

  .nav-list {
    flex-direction: column;
    align-items: flex-start;
  }

  .nav-item {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .carousel-content {
    max-width: 90%;
    padding: 5px;
  }

  #service-boxes {
    padding: 20px 10px; /* Adjust padding for smaller screens */
  }

  .service-box {
    width: 100%; /* Full width for small screens */
  }

  .language-icons img {
    width: 70px; /* Adjust size for smaller screens */
    height: 70px;
  }
}

@media (max-width: 480px) {
  .logo {
    width: 50px;
    height: 50px;
  }

  .nav-item a {
    font-size: 14px; /* Adjust font size for very small screens */
  }

  .carousel-content {
    max-width: 95%;
  }

  .service-box {
    padding: 15px; /* Reduce padding for smaller screens */
  }

  .language-icons img {
    width: 60px; /* Further adjust size for very small screens */
    height: 60px;
  }
}
