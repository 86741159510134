/* General Styles */
body {
  font-family: 'Arial', sans-serif;
  color: #333;
  margin: 0;
  padding: 0;
  background-color: #f9f9f9;
}

/* Container */
.about-container {
  padding: 20px;
  background: #ffffff;
  max-width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Main Heading */
.main-heading {
  text-align: center;
  font-size: 2.5em;
  font-weight: bold;
  color: #005bb5;
  margin: 10px 0;
  border-bottom: 3px solid #005bb5;
  padding-bottom: 8px;
}

/* Header */
.header {
  text-align: center;
  background-color: #005bb5;
  color: #ffffff;
  padding: 30px 20px;
  border-bottom: 4px solid #004494;
  margin: 0 auto;
}

.header h1 {
  margin: 0;
  font-size: 2em;
  font-weight: bold;
}

.header p {
  font-size: 1em;
  margin: 5px 0 0;
}

/* Mission and Vision */
.mission-vision {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 10px 20px;
}

.mission, .vision {
  flex: 1;
  min-width: 260px;
  margin: 10px;
  padding: 15px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.mission h2, .vision h2 {
  font-size: 1.8em;
  color: #005bb5;
  margin-bottom: 5px;
  border-bottom: 1px solid #005bb5;
  padding-bottom: 5px;
}

.mission p, .vision p {
  font-size: 1em;
  line-height: 1.5;
}

/* Services */
.services {
  text-align: center;
  padding: 10px 10px;
}

.services h2 {
  font-size: 1.8em;
  color: #005bb5;
  margin-bottom: 10px;
  position: relative;
  display: inline-block;
}

.services h2::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: -6px;
  height: 4px;
  width: 50px;
  background-color: #0073e6;
  border-radius: 2px;
}

/* Service Item with Enhanced 3D Animation */
.service-item {
  display: inline-block;
  width: 200px;
  margin: 10px;
  padding: 15px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.6s ease, box-shadow 0.6s ease, background-color 0.6s ease;
  transform-style: preserve-3d;
}

.service-item:hover {
  transform: rotateY(25deg) rotateX(15deg) scale(1.1); /* Enhanced 3D transformation */
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3); /* Enhanced shadow */
  background-color: #f1f1f1;
}

.service-item:hover .icon {
  transform: translateZ(20px); /* Move icon slightly forward in 3D space */
}

.service-item .icon {
  font-size: 2.5em;
  color: #0073e6;
  margin-bottom: 8px;
  transition: transform 0.6s ease; /* Add transition to icon */
}

.service-item h3 {
  font-size: 1.4em;
  color: #005bb5;
  margin: 5px 0;
}

.service-item p {
  font-size: 0.9em;
  color: #666;
}

/* Responsive Design */
@media (max-width: 768px) {
  .mission-vision {
    flex-direction: column;
    align-items: center;
  }

  .mission, .vision {
    margin: 0 0 10px 0;
  }

  .service-item {
    width: 100%;
    max-width: 280px;
  }
}
