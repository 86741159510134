/* Services Section */
#Offer {
  padding: 40px; /* Increased padding for a more spacious look */
  background-color: #f9f9f9;
}

.Offer-container {
  display: flex;
  flex-direction: row; /* Keep image on the right and content on the left on larger screens */
  align-items: center;
  max-width: 1400px; /* Increased max-width for a larger container */
  margin: 0 auto; /* Center container horizontally */
  width: 100%;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.Offer-content {
  flex: 1;
  padding: 30px; /* Increased padding for content */
}

.Offer-content h2 {
  font-size: 2.5em; /* Increased font size */
  margin-bottom: 20px;
  color: #333;
}

.Offer-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.Offer-item .icon {
  margin-right: 10px;
  color: #007BFF; /* Icon color */
  font-size: 1.8em; /* Increased icon size */
}

.Offer-content h5 {
  font-size: 1.4em; /* Increased font size */
  line-height: 1.6;
  margin: 0;
  color: #666;
}

.Offer-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px; /* Increased padding around the image */
  position: relative;
}

.circle-container {
  width: 450px;  /* Increased width */
  height: 450px; /* Increased height */
  border-radius: 50%;
  background-color: #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.circle-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  transition: transform 0.3s ease-in-out; /* Smooth transition for the animation */
}

.circle-container:hover img {
  transform: scale(1.1); /* Scale image when hovered */
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .Offer-content h2 {
    font-size: 2.2em; /* Slightly smaller font size */
    margin-bottom: 15px;
  }

  .Offer-content h5 {
    font-size: 1.2em; /* Slightly smaller font size */
  }

  .circle-container {
    width: 350px;  /* Adjusted for smaller screens */
    height: 350px; /* Adjusted for smaller screens */
  }
}

@media (max-width: 768px) {
  .Offer-container {
    flex-direction: column; /* Stack image and content vertically on tablets and smaller screens */
  }

  .Offer-content {
    padding: 20px; /* Adjust padding for mobile devices */
  }

  .Offer-content h2 {
    font-size: 1.8em; /* Adjust font size for smaller screens */
    margin-bottom: 10px; /* Reduced margin */
  }

  .Offer-content h5 {
    font-size: 1em; /* Adjust font size for smaller screens */
  }

  .Offer-image {
    width: 100%;
    padding: 0; /* Remove padding around the image */
  }

  .circle-container {
    width: 300px;  /* Adjusted for smaller screens */
    height: 300px; /* Adjusted for smaller screens */
  }
}

@media (max-width: 480px) {
  .Offer-content {
    padding: 15px; /* Further reduced padding for very small screens */
  }

  .Offer-content h2 {
    font-size: 1.5em; /* Smaller font size for very small screens */
  }

  .Offer-content h5 {
    font-size: 0.9em; /* Smaller font size for very small screens */
  }

  .circle-container {
    width: 250px;  /* Adjusted for very small screens */
    height: 250px; /* Adjusted for very small screens */
  }
}
