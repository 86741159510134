/* Chatbot Button */
.chatbot-button {
    position: fixed;
    bottom: 20px;
    left: 20px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    z-index: 1000;
}

/* Adjusting Chatbot Button Icon Size and Color */
.chatbot-button i {
    font-size: 60px; /* Adjust icon size */
    color: #007bff; /* Change this to your desired color */
}

/* Chatbot Container */
.chatbot-container {
    position: fixed;
    bottom: 80px; /* Adjusted to avoid overlap with buttons */
    left: 20px;
    width: 350px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    max-height: 500px;
}

/* Close Button */
.close-button {
    position: absolute;
    top: 5px;
    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 16px;
    cursor: pointer;
}

/* Chatbot Messages */
.chatbot-messages {
    padding: 10px;
    max-height: 400px;
    overflow-y: auto;
    border-bottom: 1px solid #ccc;
}

/* Message Styling */
.message {
    margin-bottom: 10px;
    padding: 8px 12px;
    border-radius: 5px;
}

.message.user {
    background-color: #e0f7fa;
    align-self: flex-end;
}

.message.bot {
    background-color: #f1f8e9;
    align-self: flex-start;
}

/* Form Styling */
form {
    display: flex;
    padding: 10px;
    border-top: 1px solid #ccc;
}

input[type="text"] {
    flex: 1;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
}

button[type="submit"] {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 8px 16px;
    margin-left: 10px;
    border-radius: 5px;
    cursor: pointer;
}

button[type="submit"]:hover {
    background-color: #0056b3;
}
