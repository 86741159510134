/* ValueAddition Page Styles */
.Value-addition-page {
  background-color: #f9f9f9;
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Value-addition {
  max-width: 1200px;
  width: 100%;
  text-align: center;
}

.Value-addition h2 {
  font-size: 2.5em;
  margin-bottom: 40px;
  color: #333;
}

.Value-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.Value-card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

@keyframes highlight {
  0% {
    transform: scale(1);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  50% {
    transform: scale(1.1);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
}

.Value-card:hover {
  animation: highlight 0.5s ease-in-out forwards;
}

.Value-card .icon {
  font-size: 3em;
  margin-bottom: 15px;
  color: #007BFF; /* Primary color */
  transition: transform 0.3s ease;
}

@keyframes iconHighlight {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(20deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.Value-card:hover .icon {
  animation: iconHighlight 0.5s ease-in-out forwards;
}

.Value-card p {
  font-size: 1.2em;
  color: #666;
}
