/* WhatsApp Button */
.whatsapp-button {
    position: fixed;
    bottom: 20px; /* Position from the bottom */
    right: 20px;  /* Align it with the right edge */
    background-color: transparent;
    border: none;
    cursor: pointer;
    z-index: 1000;
}

/* Scroll Button */
.scroll-button {
    position: fixed;
    bottom: 90px; /* Increased bottom margin to create space above WhatsApp button */
    right: 20px;  /* Align it with the WhatsApp button horizontally */
    background-color: #007bff; /* Button color */
    border: none;
    border-radius: 50%; /* Make the button circular */
    width: 50px; /* Button width */
    height: 50px; /* Button height */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.scroll-button:hover {
    background-color: #0056b3; /* Darker shade on hover */
}

.scroll-button svg {
    color: white; /* Icon color */
    font-size: 24px; /* Icon size */
}
