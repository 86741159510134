/* General Styles */
body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
}

h1, h2 {
  color: #333;
}

p {
  color: #555;
}

/* Banner Styles */
.banner {
  position: relative;
  text-align: center;
  margin-bottom: 30px;
}

.farmer-image {
  width: 100%;
  height: auto;
}

.banner-text {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
}

/* Course Grid Styles */
.course-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
}

/* Course Card Styles */
.course-card {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  border: 1px solid #ddd;
}

.course-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Price Styles */
.price {
  font-weight: bold;
  color: #28a745; /* Green for price */
  margin: 10px 0;
}

/* Button Container Styles */
.button-container {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

/* Button Styles */
.enroll-button, .test-button {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.enroll-button {
  background-color: #28a745; /* Green for enroll */
  color: white;
  transition: background-color 0.3s;
}

.test-button {
  background-color: #007bff; /* Blue for test */
  color: white;
  transition: background-color 0.3s;
}

.enroll-button:hover {
  background-color: #218838; /* Darker green on hover */
}

.test-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

/* Modal Styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.modal h2 {
  margin-top: 0;
}

.modal form {
  display: flex;
  flex-direction: column;
}

.modal input, .modal select, .modal textarea {
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.modal textarea {
  resize: vertical;
}

.modal button {
  padding: 10px;
  background-color: #28a745; /* Green for submit */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.modal button:hover {
  background-color: #218838; /* Darker green on hover */
}

/* Responsive Styles */
@media (max-width: 768px) {
  .course-grid {
    grid-template-columns: 1fr; /* Stack cards on smaller screens */
  }

  .modal-content {
    width: 90%; /* Make modal responsive */
  }
}
