/* Modal overlay to cover the entire screen */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Modal content styling */
.modal-content {
  background: #fff;
  border-radius: 8px;
  width: 80%;
  max-width: 800px;
  display: flex;
  overflow: hidden;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  position: relative;
}

/* Close button styling */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #e74c3c;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

/* Modal body layout */
.modal-body {
  display: flex;
  width: 100%;
}

/* Left side for product image */
.modal-left {
  flex: 1;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-product-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

/* Right side for product details and form */
.modal-right {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.modal-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.modal-description {
  font-size: 16px;
  margin-bottom: 20px;
}

.modal-price {
  font-size: 20px;
  font-weight: bold;
  color: #27ae60;
  margin-bottom: 20px;
}

/* Form styling */
.purchase-form {
  display: flex;
  flex-direction: column;
}

.purchase-form label {
  margin-bottom: 15px;
}

.form-input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
}

.submit-button {
    background-color: #4caf50;
    color: white;
    padding: 5px;
    border: none;
    cursor: pointer;
    font-size: 18px;
    margin-top: 5px;
    width: 30%;
    position: absolute;
    bottom: 25px;
}

.submit-button:hover {
  background-color: #2980b9;
}

.add-to-cart-button {
  background-color: #2ecc71;
  color: white;
  border: none;
  padding: 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
}

.add-to-cart-button:hover {
  background-color: #27ae60;
}
