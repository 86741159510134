.terms-container {
    max-width: 800px;
    margin: 20px auto;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
  .terms-container h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .terms-container h2 {
    margin-top: 20px;
  }
  
  .terms-container p,
  .terms-container li {
    margin: 10px 0;
  }
  
  .terms-container a {
    color: #007bff;
    text-decoration: none;
  }
  
  .terms-container a:hover {
    text-decoration: underline;
  }
  