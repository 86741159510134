/* Import the necessary CSS files */
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';



.App {
  text-align: center;
}
.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content-section {
  width: 80%;
  margin: 20px 0;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 8px;
}



.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
body, html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%; 
  overflow-x: hidden;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
  
}
.whatsapp-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  background-color: #25D366; /* WhatsApp Green */
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.whatsapp-button img {
  width: 60px;
  height: 60px;
}
/* App.css */
.whatsapp-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  background-color: #25D366; /* WhatsApp Green */
  border-radius: 50%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.whatsapp-button img {
  width: 60px;
  height: 60px;
}


