/* Header */
.header {
  background-color: #ffffff;
  padding: 10px 0;
  border-bottom: 1px solid #e0e0e0;
  position: relative;
  z-index: 100; /* Ensure the header stays above other elements */
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.logo {
  width: 60px;
  height: 60px;
}

.logo-img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.navbar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.nav-list {
  display: flex;
  list-style: none;
}

.nav-item {
  margin-left: 20px;
}

.nav-item a {
  text-decoration: none;
  color: #333;
  font-weight: bold;
}

.hamburger {
  display: none; /* Hide hamburger by default on larger screens */
  flex-direction: column;
  cursor: pointer;
  padding: 10px;
}

.hamburger span {
  height: 3px;
  width: 25px;
  background-color: #333;
  margin: 4px 0;
  border-radius: 3px;
}

@media (max-width: 768px) {
  .nav-list {
    display: none; /* Hide menu by default */
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh; /* Full viewport height */
    background-color: white;
    border-right: 1px solid #ccc;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    width: 180px; /* Adjusted width of the side menu */
    transform: translateX(-100%); /* Initially hide off-screen */
    transition: transform 0.3s ease; /* Smooth slide-in effect */
    z-index: 1000; /* Ensure it stays above other content */
  }

  .nav-list.active {
    display: flex; /* Show menu when active */
    transform: translateX(0); /* Slide in from the left */
  }

  .nav-item {
    margin: 15px 0; /* Reduced margin for mobile */
    text-align: center; /* Center nav items for mobile */
    width: 100%;
  }

  .hamburger {
    display: flex; /* Show the hamburger button */
  }

  /* Add margin to main content when nav menu is active */
  .content {
    margin-left: 180px; /* Adjusted margin to account for menu width */
    transition: margin-left 0.3s ease; /* Smooth transition for margin change */
  }

  .nav-list.active ~ .content {
    margin-left: 0; /* Reset margin when menu is closed */
  }
}

/* Carousel */
.carousel-container {
  margin-top: 20px;
  position: relative;
}

.carousel-slide img {
  width: 100%;
  height: auto;
}

.carousel-content h3,
.carousel-content p {
  color: #000; /* Change to a darker color (black) */
  font-weight: 600; /* Increase font weight for better readability */
  background-color: rgba(255, 255, 255, 0.8); /* Optional: Add a semi-transparent white background */
  padding: 10px; /* Adds padding around the text */
  border-radius: 5px; /* Slightly round the corners */
}

.carousel-content {
  display: flex;
  flex-direction: column; /* Stacks text and button vertically */
  justify-content: center;
  align-items: center;
  padding: 10px; /* Add padding around the content */
  text-align: center;
}

.carousel-content {
  padding-bottom: 60px; /* Ensure space below the text for the button */
}

.purchase-button {
  background-color: #007bff;
  color: white;
  padding: 12px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  width: 50%;
  margin-top: 5px;
  display: block;
}

.purchase-button:hover {
  background-color: #0056b3;
}

.price {
  font-weight: bold;
  font-size: 16px;
  margin-top: 5px;
}

/* Modal */
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 200;
}

.modal-content {
  background: white;
  padding: 20px;
  /* max-width: 700px; */
  width: 100%;
  position: relative;
  border-radius: 8px;
  padding: 20px; /* Add padding for spacing */
  max-width: 400px; /* Adjust maximum width for better appearance */
  margin: auto;
  overflow: hidden;
}
.modal-header, .modal-body {
  margin-bottom: 15px; /* Space between header and body */
}
.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}

.modal-body {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.modal-left {
  flex: 1 1 45%;
  margin-right: 1px;
  flex-direction: column;

}

.modal-image {
  width: 100%;
  height: auto;
  margin-bottom: 30px;
}

.modal-right {
  flex: 1 1 45%;
  display: flex;
  flex-direction: column;
}

/* Input Text Box */
input[type="text"], input[type="tel"] {
  width: 100%; /* Full width for input */
  padding: 10px; /* Add padding */
  border: 1px solid #ccc; /* Border for better visibility */
  border-radius: 5px; /* Rounded corners */
}
button {
  transition: background-color 0.3s ease; /* Smooth transition for hover */
}
button:hover {
  background-color: #0056b3; /* Change color on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow effect */
}

#phone-input {
  height: 50px; /* Increase height of the input box */
  font-size: 18px; /* Adjust font size to be medium */
}
@media (max-width: 768px) {
  .submit-button {
      position: absolute;
      width: 50%;
      left: 5%;
      bottom: 109px;
  }
}

/* Submit Button */
.submit-button {
  background-color: #4caf50;
  color: white;
  padding: 5px;
  border: none;
  cursor: pointer;
  font-size: 18px; /* Increase font size for better visibility */
  margin-top: 15px; /* Increase margin to add space between input and button */
  width: 30%; /* Make the button full width */
  position: absolute;
  bottom: 250px; /* Position the button at the bottom */
}

.submit-button:hover {
  background-color: #45a049;
}

/* Modal Body for Mobile */
@media (max-width: 768px) {
  .purchase-button {
    margin-top: 20px;
    width: auto; /* Adjust width for mobile view */
    padding: 10px;
    font-size: 14px; /* Slightly smaller font size */
  }
  
  .purchase-button {
    width: 50%; /* Full width on mobile */
  }

  .modal-left {
    margin-bottom: 20px;
  }

  .submit-button {
    position: fixed; /* Fix the button to the bottom of the page */
    width: 50%;
    left: 5%;
    bottom: 5px; /* Position button at the bottom */
  }
}



/* Toggle Button */
.toggle-sidebar {
  position: fixed;
  top: 20px;
  left: 5px;
  background-color: #007bff;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  z-index: 1001; /* Button stays above sidebar */
}





/* Ensure the sidebar does not cover content on small screens */
@media (max-width: 768px) {
  .sidebar {
    transform: translateX(-100%); /* Hide the sidebar off-screen by default */
  }

  .sidebar.open {
    transform: translateX(0); /* Show the sidebar when open */
  }
  
  .main-content {
    margin-left: 0; /* Reset margin on small screens */
  }
}


/* Toggle Button */


/* Ensure the sidebar does not cover content on small screens */
@media (max-width: 768px) {
  .sidebar {
    transform: translateX(-100%); /* Hide the sidebar off-screen by default */
  }

  .sidebar.open {
    transform: translateX(0); /* Show the sidebar when open */
  }
  
  .main-content {
    margin-left: 0; /* Reset margin on small screens */
  }
}


/* Toggle Button */
.toggle-sidebar {
  position: fixed;
  top: 20px;
  left: 5px;
  background-color: #007bff;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  z-index: 1001; /* Ensure the button stays above the sidebar */
}

/* Ensure the sidebar does not cover content on small screens */
@media (max-width: 768px) {
  .sidebar {
    transform: translateX(-100%); /* Hide the sidebar off-screen by default */
  }

  .sidebar.open {
    transform: translateX(0); /* Show the sidebar when open */
  }
  
  .main-content {
    margin-left: 0; /* Reset margin on small screens */
  }
}


/* Toggle Button */
.toggle-sidebar {
  position: fixed;
  top: 20px;
  left: 5px;
  background-color: #007bff;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  z-index: 1001; /* Ensure the button stays above the sidebar */
}

/* Ensure the sidebar does not cover content on small screens */
@media (max-width: 768px) {
  .sidebar {
    transform: translateX(-100%); /* Hide the sidebar off-screen by default */
  }

  .sidebar.open {
    transform: translateX(0); /* Show the sidebar when open */
  }
  
  .main-content {
    margin-left: 0; /* Reset margin on small screens */
  }
}

/* Fixes for mobile view */
body, html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  overflow-x: hidden;
}

@media (max-width: 768px) {
  .purchase-button {
    position: fixed; /* Keeps button fixed at the bottom of the screen */
    bottom: 5px;    /* Adjusts distance from bottom */
    left: 5px;      /* Adjusts distance from left side */
    right: 5px;     /* Adjusts distance from right side */
    width: auto;    /* Adjust width to fit the mobile screen */
    padding: 10px;  /* Adjust padding for mobile view */
    z-index: 1000;  /* Ensures the button stays above other elements */
    font-size: 14px;/* Slightly smaller font on mobile */
  }
}

/* Increase Phone Input Size */
@media (max-width: 768px) {
  #phone-input {
    width: 100%;
    height: 80px; /* Increase height */
    font-size: 24px; /* Larger font size */
    padding: 10px; /* Add padding */
    margin-bottom: 20px; /* Add margin between input and button */
  }
}
